import styled from 'styled-components';

const Main = styled.div`
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Main;
